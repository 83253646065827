<template>
  <div class="drawer-wrapper">
    <div class="drawer-operate-btn">
      <i class="el-icon-close" @click="handleClose" />
    </div>
    <!-- <m-form
      ref="myForm"
      :form-data="baseFormData"
      label-position="right"
      :label-width="120"
      :extend-config="extendFormConfig"
    /> -->
    <div class="materialinfo">
      <div class="materialinfo-title">
        {{ $t('lang.gles.orderMonitor.taskMaterialInfo') }}
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :extend-config="tableConfig"
        :page-data="pageData"
      >
        <template #clickSlot="{ row }">
          <span v-if="row.materialId" class="list-detail-item" @click="onAttr(row)">
            {{ $t('lang.gles.common.view') }}
          </span>
        </template>
      </m-table>
    </div>
    <batch-property-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :mode="mode"
      :init-row="batchPropertyRow"
      :list="batchPropertyDetail"
    />
  </div>
</template>
<script>
import { getMaterialTableData } from '../data'
import BatchPropertyDialog from '../../../orderManage/stockAdjustmentOrder/components/batchPropertyDialog'
export default {
  name: 'MaterialItem',
  components: {
    BatchPropertyDialog
  },
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // 批属性弹窗
      detailDialogVisible: false,
      batchPropertyRow: {},
      batchPropertyDetail: [],
      detailObj: {},
      tableData: [],
      extendFormConfig: {
        isNeedBtn: false,
        isGroup: true
      },
      tableConfig: {
        sortNum: true,
        checkBox: false
      },
      pageData: null
    }
  },
  computed: {
    tableItem() {
      return getMaterialTableData(this, this.options)
    }
  },
  created() {
    this.listMaterialDetail()
  },
  methods: {
    async listMaterialDetail() {
      const params = {
        receiptNo: this.rowDetail.receiptNo,
        receiptType: this.rowDetail.receiptType,
        materialIdList: [
          this.rowDetail.materialId
        ]
        // 'receiptNo': 'RKD20220246000031',
        // 'receiptType': 101
      }
      const { data } = await this.$httpService(
        this.$apiStore.monitorManage('listMaterialDetail'),
        params
      )
      data && data.forEach(item => {
        item.materialName = item.materialId
      })
      this.tableData = data
    },
    // 查看批属性
    async onAttr(row) {
      // console.log(row)
      const params = {
        id: row.materialId
      }
      this.batchPropertyRow = {}
      // row.materialBatchNo = 123
      const arr = [
        row.manufactureDate, // 生产日期
        row.expirationDate, // 过期日期
        row.materialBatchNo,
        row.batchProp1,
        row.batchProp2,
        row.batchProp3,
        row.batchProp4,
        row.batchProp5,
        row.batchProp6,
        row.batchProp7,
        row.batchProp8,
        row.batchProp9,
        row.batchProp10
      ]
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('queryMaterialDetail'),
        params
      )
      if (code) return
      this.batchPropertyDetail = data.batchPropertyDetail || []
      if (this.batchPropertyDetail.length) {
        this.batchPropertyDetail.forEach((item, index) => {
          this.batchPropertyRow[item.propertyLabelCode] = arr[index]
        })
        this.detailDialogVisible = true
      } else {
        this.$message.error(this.$t('lang.gles.orderManage.batchPropertyTips'))
      }
    },
    // 关闭
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-wrapper {
  padding: 10px 0 0 0 !important;
}
.drawer-operate-btn {
  height: 40px;
}
.materialinfo {
  margin-bottom: 10px;
}
.materialinfo-title {
  margin-bottom: 10px;
  font-weight: 800;

  &::before {
    content: "";
    display: inline-block;
    height: 21px;
    width: 4px;
    border-radius: 4px;
    background: #409eff;
    margin-right: 10px;
    vertical-align: text-bottom;
  }
}
</style>
