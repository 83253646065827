<template>
  <el-card class="common-search-card">
    <div class="search-input">
      <el-input
        :value="innerValue"
        :name="name"
        v-bind="$attrs"
        class="input-with-select"
        @input="$emit('update:value', _.trim($event))"
      >
        <el-button slot="append" type="primary" @click="doSearchBase">
          {{ $t('lang.gles.common.query') }}
        </el-button>
      </el-input>
    </div>
    <el-button v-if="isShowMoreSearch" @click="toggleMoreQueryVisible(true)">
      {{ $t('lang.gles.common.moreQuery') }}
    </el-button>

    <more-query
      ref="myForm"
      :class="moreClass"
      :width="width"
      :query-list="queryList"
      :visible.sync="moreQueryVisible"
      @query="doSearchBaseMore"
      @update:visible="toggleMoreQueryVisible(false)"
    />
  </el-card>
</template>

<script>
/**
 * 标准查询
 *
 * 入参
 * @param name { String } 基础查询的propName, 必填
 * @param queryList { Array } 更多查询的配置内容, 与 @/components/moreQuery保持一致 必填
 * @param value { String } 查询的默认内容, 选填
 * @param moreClass { String } 更多查询弹出层的 className 选填
 * * 支持所有el-input组件的属性
 *
 * 事件
 * @search 所有类型的查询均触发
 * @baseSearch 基础查询后触发
 * @moreSearch 更多查询后触发
 *
 * 方法
 * search(data, type) 查询
 * @param data { Object } 查询条件
 * @param type { String } 查询类型 选填 base, more, 默认 base
 * 调用此方法会触发事件 @search 根据type的不同分别触发@baseSearch 和 @moreSearch
 *
 * histSearch(data) 历史查询
 * @param data  { Object } 查询条件
 * 如果没有传入data, 则触发上一次的查询条件,
 * 如果没有传入data, 则会和历史查询条件做Object.assign然后进行查询
 * 通常情况下只需要传入 pageData 数据即可
 */
import MoreQuery from './index.vue'

export default {
  components: { MoreQuery },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: () => ''
    },
    isShowMoreSearch: {
      type: Boolean,
      default: true
    },
    queryList: {
      type: Array,
      default: () => []
    },
    moreClass: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => '50%'
    },
    moreVisible: {
      type: Boolean,
      default: false
    }
    // resetPageData: {
    //   type: Boolean,
    //   default: true
    // }
  },
  data() {
    return {
      moreQueryVisible: false,
      history: {
        type: 'base',
        data: {}
      },
      innerValue: '',
      resetPageData: true
    }
  },
  watch: {
    value() {
      this.innerValue = this.trimValue(this.value)
    }
  },
  methods: {
    toggleMoreQueryVisible(flag) {
      this.moreQueryVisible = flag
      this.$emit('update:moreVisible', flag)
    },
    trimValue(value) {
      return typeof value === 'string' ? this._.trim(value) : value
    },
    doSearchBase() {
      const { value, name } = this
      const data = { [name]: this.trimValue(value) }
      this.$parent.resetPageData()
      this.$emit('update:value', '')
      this.search(data, 'base')
    },
    doSearchBaseMore(data) {
      this.$parent.resetPageData()
      this.search(data, 'more')
      this.$refs.myForm.reset()
    },
    // 触发查询
    search(data = {}, type = 'base') {
      this.history = { data, type }
      this.$emit('search', data)
      this.$emit(`${type}Search`, data)
    },

    // 触发上一次的查询条件
    histSearch(assData) {
      const { data, type } = this.history
      this.search({ ...data, ...assData }, type)
    },
    getHistorySearchData() {
      return this.history.data
    }
  }
}
</script>

<style scoped lang="scss"></style>
