// import mapVerify from '@/utils/formValidate'
import { t } from '@/libs_sz/locale'
export const getFormatter = (data, value) => {
  const findValue = data?.find(t => t.value == value)?.label ?? value
  return findValue === 0 ? '' : findValue
}
// const getValue = value => (value ? String(value) : String(value) === '0' ? '0' : '')
export const billStatus_dict = [
  {
    value: '0',
    label: t('创建')
  },
  {
    value: '10',
    label: t('生成取料任务')
  },
  {
    value: '11',
    label: t('取料中')
  },
  {
    value: '12',
    label: t('取料完成')
  },
  {
    value: '20',
    label: t('生成上料任务')
  },
  {
    value: '21',
    label: t('上料中')
  },
  {
    value: '22',
    label: t('上料完成')
  },
  {
    value: '30',
    label: t('生成下料任务')
  },
  {
    value: '31',
    label: t('下料中')
  },
  {
    value: '32',
    label: t('下料完成')
  },
  {
    value: '40',
    label: t('生成放料任务')
  },
  {
    value: '41',
    label: t('放料中')
  },
  {
    value: '42',
    label: t('放料完成')
  },
  {
    value: '100',
    label: t('完成')
  },
  {
    value: '99',
    label: t('取消')
  }
]
export const excepteAbnormalList = [
  {
    value: '1',
    label: t('lang.gles.common.yes')
  },
  {
    value: '0',
    label: t('lang.gles.common.no')
  }
]
// 获取 更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 单据号
    {
      name: 'receiptNo',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.stock.orderCode')
    },
    // 外部单据号
    {
      name: 'externalReceiptNo',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.hostOrderCode')
    },
    // 单据类型
    {
      name: 'receiptType',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.receiptType'),
      filterable: true,
      data: that.receiptTypeList
      // mchange: that.handleChange
    },
    // 执行模式
    {
      name: 'executeMode',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.executeMode'),
      data: that.executeModeList
    },
    // 单据状态
    {
      name: 'receiptStatus',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.receiptStatus'),
      filterable: true,
      data: that.moreReceiptStatusList || []
    },
    // 任务状态
    {
      name: 'taskStatus',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.taskStatus'),
      filterable: true,
      data: that.moreTaskStatusList || []
    },
    // 需求工位
    {
      name: 'demandLocationCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderMonitor.demandLocationCode')
    },
    // 执行异常
    {
      name: 'executeExceptionFlag',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderMonitor.excepteAbnormal'),
      filterable: true,
      data: that.excepteAbnormalList || []
    },
    // 物料编码
    {
      name: 'materialId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.material.materialCode'),
      data: that.materialCodeList || []
    },
    // 容器编码
    {
      name: 'containerId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.containerId'),
      data: that.containerArchivesAllList || []
    },
    // 周转容器编码
    {
      name: 'turnoverContainerId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      data: that.turnoverContainerCodeList || []
    },
    // 目标货位/库位
    {
      name: 'targetPositionCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderMonitor.targetPositionCode')
    },
    // 任务ID
    {
      name: 'taskId',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: `${that.$t('lang.gles.orderManage.task')}ID`
    },
    // 机器人编号
    {
      name: 'robotCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.robotCode')
    },
    // 任务类型
    {
      name: 'taskType',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.strategy.taskType'),
      filterable: true,
      data: that.moreTaskTypeList || []
    }
  ]
  return moreQueryData
}

// 查询列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 单据号
    {
      prop: 'receiptNo',
      isShow: true,
      fixed: true,
      label: that.$t('lang.gles.stock.orderCode'),
      minWidth: that.tableData[0]?.receiptNoMaxWid || 220,
      showOverflowTooltip: true,
      slotName: 'clickSlot'
    },
    // 单据类型
    {
      prop: 'receiptType',
      isShow: true,
      fixed: true,
      label: that.$t('lang.gles.orderManage.receiptType'),
      width: 100,
      showOverflowTooltip: true,
      formatter(row, column) {
        return that.getDictLabel(that.receiptTypeList, row[column])
      }
    },
    // 执行模式
    {
      prop: 'executeMode',
      isShow: true,
      fixed: true,
      label: that.$t('lang.gles.workflow.executeMode'),
      formatter(row, column) {
        return that.getDictLabel(that.executeModeList, row[column] ?? 0)
      }
    },
    // 外部单据号
    {
      prop: 'externalReceiptNo',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.interface.hostOrderCode')
    },
    // 单据状态
    {
      prop: 'receiptStatus',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.interface.receiptStatus'),
      formatter(row, column) {
        return that.getDictLabel([...that.receiptStatusList, ...that.moreReceiptStatusList], row[column])
      }
    },
    // 任务ID
    {
      prop: 'taskId',
      isShow: true,
      label: `${that.$t('lang.gles.orderManage.task')}ID`,
      minWidth: that.tableData[0]?.taskIdMaxWid || 220,
      showOverflowTooltip: true
    },
    // 任务类型
    {
      prop: 'taskType',
      isShow: true,
      label: that.$t('lang.gles.strategy.taskType'),
      width: 100,
      showOverflowTooltip: true,
      formatter(row, column) {
        return that.getDictLabel(that.taskTypeList, row[column])
      }
    },
    // 任务状态
    {
      prop: 'taskStatus',
      isShow: true,
      label: that.$t('lang.gles.interface.taskStatus'),
      width: 100,
      showOverflowTooltip: true,
      formatter(row, column) {
        return that.getDictLabel([...that.taskStatusList, ...that.moreTaskStatusList], row[column])
      }
    },
    // 需求工位
    {
      prop: 'demandLocationCode',
      isShow: true,
      label: that.$t('lang.gles.orderMonitor.demandLocationCode'),
      minWidth: that.tableData[0]?.demandLocationCodeMaxWid || 100,
      showOverflowTooltip: true,
      slotName: 'stationSlot'
    },
    // 物料编码
    {
      prop: 'materialCode',
      isShow: true,
      label: that.$t('lang.gles.material.materialCode'),
      minWidth: that.tableData[0]?.materialCodeMaxWid || 110,
      showOverflowTooltip: true,
      slotName: 'materialSlot'
    },
    // 容器编码
    {
      prop: 'containerCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.containerId'),
      width: 110,
      showOverflowTooltip: true
    },
    // 周转容器编码
    {
      prop: 'turnoverContainerCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      showOverflowTooltip: true,
      width: 110
    },
    // 目标货位/库位
    {
      prop: 'targetPositionCode',
      isShow: true,
      label: that.$t('lang.gles.orderMonitor.targetPositionCode'),
      showOverflowTooltip: true,
      width: 120
    },
    // 任务执行说明
    {
      prop: 'taskExecuteInstructions',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderMonitor.taskExecuteInstructions'),
      formatter(row, column) {
        return that.$t(row[column])
      }
    },
    // 机器人编号
    {
      prop: 'robotCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.robotCode'),
      showOverflowTooltip: true,
      minWidth: that.tableData[0]?.robotCodeMaxWid || 100,
      slotName: 'robotSlot'
    },
    // 作业方式
    {
      prop: 'operatingMode',
      isShow: true,
      label: that.$t('lang.gles.workflow.operatingMode'),
      showOverflowTooltip: true,
      width: 100,
      formatter(row, column) {
        return that.getDictLabel(that.operatingModeList, row[column])
      }
    },
    // 创建人
    {
      prop: 'createUser',
      isShow: true,
      width: 100,
      label: that.$t('lang.gles.common.createUser')
    },
    // 创建时间
    {
      prop: 'createTime',
      isShow: true,
      label: that.$t('lang.gles.common.createTime'),
      formatter: { type: 'time' },
      showOverflowTooltip: true,
      minWidth: 160
    }
  ]
  return searchTableItem
}

// 基础信息
export const getEditBaseFormData = (that, row, options) => {
  return [
    // 单据号
    {
      name: 'receiptNo',
      value: row.receiptNo || '',
      span: 8,
      label: that.$t('lang.gles.stock.orderCode')
    },
    // 外部单据号
    {
      name: 'externalReceiptNo',
      value: row.externalReceiptNo || '',
      span: 8,
      label: that.$t('lang.gles.interface.hostOrderCode')
    },
    // 执行模式
    {
      name: 'executeMode',
      value: getFormatter(options.executeModeList, row.executeMode),
      span: 8,
      label: that.$t('lang.gles.workflow.executeMode')
    },
    // 作业方式
    {
      name: 'operatingMode',
      value: getFormatter(options.operatingModeList, row.operatingMode),
      span: 8,
      label: that.$t('lang.gles.workflow.operatingMode')
    },
    // 单据类型
    {
      name: 'receiptType',
      value: getFormatter(options.receiptTypeList, row.receiptType),
      span: 8,
      label: that.$t('lang.gles.orderManage.receiptType')
    },
    // 需求工位
    {
      name: 'demandLocationCode',
      value: row.demandLocationCode || '',
      span: 8,
      label: that.$t('lang.gles.orderMonitor.demandLocationCode')
    }
  ]
}

// 物料信息
export const getMaterialTableData = (that, options) => [
  // 物料编码
  {
    prop: 'materialId',
    isShow: true,
    label: that.$t('lang.gles.material.materialCode'),
    width: 100,
    showOverflowTooltip: true,
    formatter(row, column) {
      return getFormatter(options.materialCodeList, row[column])
    }
  },
  // 物料名称
  {
    prop: 'materialId',
    isShow: true,
    label: that.$t('lang.gles.material.materialName'),
    formatter(row, column) {
      return getFormatter(options.materialNameList, row[column])
    }
  },
  // 物料数量
  {
    prop: 'materialNum',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialSum')
  },
  // 基本单位
  {
    prop: 'materialUnit',
    isShow: true,
    label: that.$t('lang.gles.common.basicUnit'),
    formatter(row, column) {
      return getFormatter(options.basicUnitList, row[column])
    }
  },
  // 计量单位
  {
    prop: 'materialCountUnit',
    isShow: true,
    label: that.$t('lang.gles.common.measureUnit'),
    formatter(row, column) {
      return getFormatter(options.basicUnitList, row[column])
    }
  },
  // 物料朝向
  {
    prop: 'materialDirection',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialToward'),
    formatter(row, column) {
      return getFormatter(options.materialTowardList, row[column])
    }
  },
  // 物料等级
  {
    prop: 'materialGrade',
    isShow: true,
    label: that.$t('lang.gles.common.gradeStatus'),
    formatter(row, column) {
      return getFormatter(options.gradeStatusList, row[column])
    }
  }
  // // 批属性
  // {
  //   prop: 'batchProperties',
  //   isShow: true,
  //   width: 80,
  //   label: that.$t('lang.gles.orderManage.batchProperties'),
  //   slotName: 'clickSlot'
  // }
]

// 工位信息
export const getStationFormData = (that, row, options) => [
  {
    title: that.$t('lang.gles.orderMonitor.taskStationInfo'),
    children: [
      // 工位位置
      {
        name: 'workstationCode',
        value: row.workstationCode || '',
        span: 10,
        label: that.$t('lang.gles.workflow.workPosition')
      },
      // 工位类型
      {
        name: 'workstationTypeDec',
        value: row.workstationTypeDec,
        span: 10,
        label: that.$t('lang.gles.workflow.workPositionType')
      },
      // 所属设备
      {
        name: 'deviceCode',
        value: row.deviceCode || '',
        span: 10,
        label: that.$t('lang.gles.base.belongingDevice')
      },
      // 所属区域
      {
        name: 'areaCode',
        value: row.areaCode || '',
        span: 10,
        label: that.$t('lang.gles.base.belongingArea')
      },
      // 所属产线
      {
        name: 'productionLineCode',
        value: row.productionLineCode || '',
        span: 10,
        label: that.$t('lang.gles.base.belongingProductionLine')
      },
      // 所属车间
      {
        name: 'workshopCode',
        value: row.workshopCode || '',
        span: 10,
        label: that.$t('lang.gles.base.belongingWorksop')
      }
    ]
  }
]

// 机器人信息
export const getRobotFormData = (that, row) => [
  {
    title: that.$t('lang.gles.orderMonitor.taskRobotInfo'),
    children: [
      // 机器人ID
      {
        name: 'robotCode',
        value: row.robotCode || '',
        span: 10,
        label: that.$t('lang.gles.orderManage.robotCode')
      },
      // 机器人系列
      {
        name: 'robotModel',
        value: row.robotModel || '',
        span: 10,
        label: that.$t('lang.gles.strategy.robotSeries')
      },
      // 机器人型号
      {
        name: 'containerTypeCode',
        value: row.containerTypeCode || '',
        span: 10,
        label: that.$t('lang.gles.strategy.robotModel')
      }
    ]
  },
  {
    title: that.$t('lang.gles.orderMonitor.taskRobotGoodspositionInfo'),
    children: [
      // 机器人组件容器编码
      {
        name: 'robotContainerArchivesCode',
        value: row.robotContainerArchivesCode || '',
        span: 10,
        label: that.$t('lang.gles.orderMonitor.robotComponentContainerId')
      },
      // 机器人货位编码
      {
        name: 'robotGoodsPositionCode',
        value: row.robotGoodsPositionCode || '',
        span: 10,
        label: that.$t('lang.gles.stock.robotGoodsPositionCode')
      },
      // 机器人货位序号
      {
        name: 'robotGoodsPositionNum',
        value: row.robotGoodsPositionNum || '',
        span: 10,
        label: `${that.$t('lang.gles.stock.robotGoodsPosition')}${that.$t('lang.gles.base.index')}`
      },
      // 机器人货位面
      {
        name: 'robotSide',
        value: row.robotSide || '',
        span: 10,
        label: that.$t('lang.gles.orderMonitor.robotGoodsPositionDirection')
      }
    ]
  }
]

// 容器/物料详情列表
export const getDetailTableItems = (that, options) => {
  const searchTableItem = [
    // 物料编码
    {
      prop: 'materialCode',
      isShow: true,
      label: that.$t('lang.gles.material.materialCode'),
      width: 100,
      showOverflowTooltip: true
    },
    // 物料一级分类
    {
      prop: 'firstClassifyName',
      isShow: true,
      label: that.$t('lang.gles.common.materialFirstClassify'),
      width: 110
    },
    // 物料二级分类
    {
      prop: 'secondClassifyName',
      isShow: true,
      label: that.$t('lang.gles.common.materialSecondClassify'),
      width: 110
    },
    // 物料数量
    {
      prop: 'materialNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialSum')
    },
    // 基本单位
    {
      prop: 'materialUnit',
      isShow: true,
      label: that.$t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 计量单位
    {
      prop: 'materialCountUnit',
      isShow: true,
      label: that.$t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 物料朝向
    {
      prop: 'materialDirection',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialToward'),
      formatter(row, column) {
        return getFormatter(options.materialTowardList, row[column])
      }
    },
    // 容器类型
    {
      prop: 'containerTypeId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.containerTypePattern'),
      width: 100,
      formatter(row, column) {
        return getFormatter(options.containerTypeList, row[column])
      }
    },
    // 容器编码
    {
      prop: 'containerCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.containerId'),
      width: 100,
      showOverflowTooltip: true
    },
    // 容器数量
    {
      prop: 'containerSum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.containerSum')
    },
    // 周转容器类型
    {
      prop: 'turnoverContainerTypeId',
      isShow: true,
      label: that.$t('lang.gles.material.turnoverContainerType'),
      width: 110,
      formatter(row, column) {
        return getFormatter(options.containerTypeList, row[column])
      }
    },
    // 周转容器编码
    {
      prop: 'turnoverContainerCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      width: 110,
      showOverflowTooltip: true
    },
    // 周转容器数量
    {
      prop: 'turnoverContainerNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerSum'),
      width: 110
    },
    // 指定入/出库库区
    {
      prop: 'assignInWarehouseAreaCode',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.pageType === 'warehousing' || (that.pageType === 'upDownMaterial' && that.activeName === '1')
        ? that.$t('lang.gles.orderManage.assignInWarehouseAreaId') : that.$t('lang.gles.orderManage.assignOutWarehouseAreaId')
    },
    // 指定入/出库库位
    {
      prop: 'assignInWarehousePositionCode',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.pageType === 'warehousing' || (that.pageType === 'upDownMaterial' && that.activeName === '1')
        ? that.$t('lang.gles.orderManage.assignInWarehousePositionId') : that.$t('lang.gles.orderManage.assignOutWarehousePositionId')
    },
    // 指定入/出库货位
    {
      prop: 'assignInGoodsPositionCode',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.pageType === 'warehousing' || (that.pageType === 'upDownMaterial' && that.activeName === '1')
        ? that.$t('lang.gles.orderManage.assignInGoodsPositionId') : that.$t('lang.gles.orderManage.assignOutGoodsPositionId')
    }
  ]
  return searchTableItem
}

// 获取任务详情table
export const getTaskTableItems = (that, options) => {
  let taskTablelist = [
    // 任务ID
    {
      prop: 'taskCode',
      isShow: true,
      minWidth: 180,
      showOverflowTooltip: true,
      label: `${that.$t('lang.gles.orderManage.task')}ID`
    },
    // 任务类型
    {
      prop: 'taskType',
      isShow: true,
      label: that.$t('lang.gles.strategy.taskType'),
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.taskTypeList, row[column])
      }
    },
    // 任务状态
    {
      prop: 'taskStatus',
      isShow: true,
      label: that.$t('lang.gles.interface.taskStatus'),
      width: 100,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.taskStatusList, row[column])
      }
    },
    // 执行说明
    {
      prop: 'executeDesc',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderMonitor.executionInstructions'),
      formatter(row, column) {
        return that.$t(row[column])
      }
    },
    // 异常描述
    {
      prop: 'exceptionMsg',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.exceptionDesc'),
      formatter(row, column) {
        return that.$t(row[column])
      }
    },
    // 任务子单据号
    {
      prop: 'subReceiptNo',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderMonitor.taskChildNum'),
      width: 110
    },
    // 物料编码
    {
      prop: 'materialId',
      isShow: true,
      label: that.$t('lang.gles.material.materialCode'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.materialCodeList, row[column])
      }
    },
    // 物料数量
    {
      prop: 'materialNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialSum')
    },
    // 容器编码
    {
      prop: 'containerCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.containerId'),
      width: 110,
      showOverflowTooltip: true
    },
    // 容器数量
    {
      prop: 'containerSum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.containerSum')
    },
    // 周转容器编码
    {
      prop: 'turnoverContainerCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      width: 110,
      showOverflowTooltip: true
    },
    // 周转容器数量
    {
      prop: 'turnoverContainerSum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerSum'),
      showOverflowTooltip: true,
      width: 110
    },
    // 目标货位
    {
      prop: 'targetPositionCode',
      isShow: true,
      minWidth: 100,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderMonitor.targetGoodsPosition')
    },
    // 操作人
    {
      prop: 'createUser',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.common.handleName')
    },
    // 机器人货位面
    {
      prop: 'robotSide',
      isShow: that.pageType === 'upDownMaterial',
      label: that.$t('lang.gles.orderMonitor.robotGoodsPositionDirection'),
      showOverflowTooltip: true,
      width: 110
    },
    // 机器人货位
    {
      prop: 'robotGoodsPositionId',
      isShow: that.pageType === 'upDownMaterial',
      label: that.$t('lang.gles.stock.robotGoodsPosition'),
      showOverflowTooltip: true,
      width: 110,
      formatter(row, column) {
        return getFormatter(options.goodsPositionList, row[column])
      }
    },
    // 机器人任务
    {
      prop: 'robotTaskId',
      isShow: that.pageType === 'upDownMaterial',
      label: that.$t('lang.gles.orderManage.robotTaskId'),
      showOverflowTooltip: true,
      width: 110
    },
    // 指定入/出库库区
    {
      prop: 'assignInWarehouseAreaCode',
      isShow: that.pageType !== 'upDownMaterial',
      width: 110,
      showOverflowTooltip: true,
      label: that.pageType === 'warehousing'
        ? that.$t('lang.gles.orderManage.assignInWarehouseAreaId') : that.$t('lang.gles.orderManage.assignOutWarehouseAreaId')
    },
    // 指定入/出库库位
    {
      prop: 'assignInFactoryPositionCode',
      isShow: that.pageType !== 'upDownMaterial',
      width: 110,
      showOverflowTooltip: true,
      label: that.pageType === 'warehousing' ? that.$t('lang.gles.orderManage.assignInWarehousePositionId') : that.$t('lang.gles.orderManage.assignOutWarehousePositionId')
    },
    // 指定入/出库货位
    {
      prop: 'assignInGoodsPositionCode',
      isShow: that.pageType !== 'upDownMaterial',
      width: 110,
      showOverflowTooltip: true,
      label: that.pageType === 'warehousing' ? that.$t('lang.gles.orderManage.assignInGoodsPositionId') : that.$t('lang.gles.orderManage.assignOutGoodsPositionId')
    },
    // 创建时间
    {
      prop: 'createTime',
      isShow: true,
      label: that.$t('lang.gles.common.createTime'),
      formatter: { type: 'time' },
      showOverflowTooltip: true,
      minWidth: 160
    },
    // 完成时间
    {
      prop: 'finishTime',
      isShow: true,
      label: that.$t('lang.gles.common.completedTime'),
      formatter: { type: 'time' },
      showOverflowTooltip: true,
      minWidth: 160
    }
  ]
  taskTablelist = taskTablelist.filter(item => item.isShow)
  return taskTablelist
}

// 获取任务详情子table
export const getTaskChildTableItems = (that, options) => [
  // 容器编码
  {
    prop: 'containerCode',
    isShow: true,
    label: that.$t('lang.gles.orderManage.containerId')
  },
  // 货位编码
  {
    prop: 'goodsPositionCode',
    isShow: true,
    label: that.$t('lang.gles.orderManage.goodsPositionId')
  },
  // 周转容器编码
  {
    prop: 'turnoverContainerId',
    isShow: true,
    label: that.$t('lang.gles.orderManage.turnoverContainerId')
  },
  // 周转容器数量
  {
    prop: 'turnoverContainerSum',
    isShow: true,
    label: that.$t('lang.gles.orderManage.turnoverContainerSum')
  },
  // 物料名称
  {
    prop: 'materialId',
    isShow: true,
    label: that.$t('lang.gles.material.materialName'),
    formatter(row, column) {
      return getFormatter(options.materialNameList, row[column])
    }
  },
  // 物料编码
  {
    prop: 'materialCode',
    isShow: true,
    label: that.$t('lang.gles.material.materialCode')
  },
  // 物料数量
  {
    prop: 'materialNum',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialSum')
  },
  // 物料朝向
  {
    prop: 'materialToward',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialToward'),
    formatter(row, column) {
      return getFormatter(options.materialTowardList, row[column])
    }
  },
  // 批次
  {
    prop: 'batchNo',
    isShow: true,
    label: that.$t('lang.gles.orderManage.batchNo')
  },
  // 等级状态
  {
    prop: 'levelStatus',
    isShow: true,
    label: that.$t('lang.gles.common.gradeStatus')
  }
]

// 获取日志table
export const getLogTableItems = (that, options) => [
  // 状态
  {
    prop: 'billStatus',
    label: that.$t('lang.gles.common.statusFlag'),
    formatter(row, column) {
      // return getFormatter(options.billStatus_dict, row[column])
      return getFormatter(options.taskStatusList, row[column])
    }
  },
  // 时间
  {
    prop: 'createTime',
    label: that.$t('lang.gles.common.time')
  },
  // 操作人
  {
    prop: 'createUser',
    label: that.$t('lang.gles.common.handleName')
  }
]
