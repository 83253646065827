<template>
  <div class="drawer-wrapper">
    <div class="drawer-operate-btn">
      <el-button v-if="isAuto" type="primary" @click="handleAuto">
        {{ $t('lang.gles.orderMonitor.handleAutoBtn') }}
      </el-button>
      <el-button v-if="isRetry" type="primary" @click="handleRetry">
        {{ $t('lang.gles.orderMonitor.handleRetryBtn') }}
      </el-button>
      <el-button
        :disabled="isDisabledComplete"
        type="success"
        @click="handleComplete"
      >
        {{ $t('lang.gles.orderMonitor.handleCompleteBtn') }}
      </el-button>
      <el-button
        :disabled="isDisabledCancel"
        type="danger"
        @click="handleCancel"
      >
        {{ $t('lang.gles.workflow.taskStatus.cancel') }}
      </el-button>
      <i class="el-icon-close" @click="handleClose" />
    </div>
    <div class="baseinfo">
      <div class="baseinfo-title">
        {{ $t('lang.gles.orderMonitor.orderInfo') }}
      </div>
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
        class="basic-data-add-form"
      />
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-if="pageType === 'upDownMaterial'"
          :label="$t('lang.gles.orderMonitor.upMaterialInfo')"
          name="1"
        />
        <el-tab-pane
          v-if="pageType === 'upDownMaterial'"
          :label="$t('lang.gles.orderMonitor.downMaterialInfo')"
          name="2"
        />
        <el-tab-pane
          v-if="pageType === 'warehousing'"
          :label="$t('lang.gles.orderMonitor.inWarehouseInfo')"
          name="1"
        />
        <el-tab-pane
          v-if="pageType === 'outWarehouse'"
          :label="$t('lang.gles.orderMonitor.outWarehouseInfo')"
          name="1"
        />
        <el-tab-pane
          v-if="pageType === 'tallying'"
          :label="$t('理货信息')"
          name="1"
        />
      </el-tabs>
      <m-table
        :table-item="tableItem"
        :table-data="receiptMonitorDtVos"
        :page-data="pageData"
      />
    </div>
    <div class="taskinfo">
      <div class="taskinfo-title">
        {{ $t('lang.gles.orderMonitor.orderTask') }}
      </div>
      <m-table
        :table-item="tableTaskItem"
        :table-data="receiptMonitorTaskVos"
        :page-data="pageData"
      />
    </div>
    <div class="loginfo">
      <div class="loginfo-title">
        {{ $t('lang.gles.orderMonitor.orderLog') }}
      </div>
      <el-timeline class="loginfo-timeline">
        <el-timeline-item
          v-for="(item, index) in receiptMonitorLogVos"
          :key="index"
          :color="item.color"
          :timestamp="item.createTime"
        >
          <div>
            <span>{{ item.content }}</span>
            <span class="error-tip">{{ item.msg }}</span>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import myTransform from '@/libs_sz/utils/transform'
import {
  getEditBaseFormData,
  getDetailTableItems,
  getTaskTableItems
} from '../data'
import addMixins from '@/mixins/addMixins'

export default {
  components: {
    MForm
  },
  mixins: [addMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    isAuto: {
      type: Boolean,
      default: false
    },
    isRetry: {
      type: Boolean,
      default: false
    },
    isComplete: {
      type: Boolean,
      default: false
    },
    isDisabledComplete: {
      type: Boolean,
      default: false
    },
    isDisabledCancel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      abnormalCodeLis: [0, 11, 14, 16, 50, 99],
      activeName: '1',
      currentCom: 'WarehousingInfo',
      detailObj: {},
      receiptMonitorDtVos: [],
      receiptMonitorTaskVos: [],
      extendFormConfig: {
        isNeedBtn: false
      },
      pageData: null,
      receiptMonitorLogVos: [
        // {
        //   content: '开始',
        //   color: '#0bbd87'
        // },
        // {
        //   content: '创建',
        //   createTime: '2021-12-21 20:46',
        //   color: '#0bbd87'
        // },
        // {
        //   content: '生成取料任务',
        //   createTime: '2021-12-21 20:46',
        //   color: '#0bbd87'
        // },
        // {
        //   content: '取料中',
        //   createTime: '2021-12-21 20:46',
        //   color: '#0bbd87'
        // },
        // {
        //   content: '任务失败',
        //   createTime: '2021-12-21 20:46',
        //   msg: '无下料数据，模板配置错误',
        //   color: '#f00'
        // },
        // {
        //   content: '取消',
        //   createTime: '2021-12-21 20:46'
        //   // color: '#f00'
        // },
        // {
        //   content: '结束',
        //   color: '#f00'
        // }
      ]
    }
  },
  computed: {
    pageType() {
      let type = ''
      if (this.rowDetail.receiptType > 0 && this.rowDetail.receiptType < 100) {
        type = 'upDownMaterial'
      } else if (
        this.rowDetail.receiptType > 100 &&
        this.rowDetail.receiptType < 200
      ) {
        type = 'warehousing'
      } else if (
        this.rowDetail.receiptType > 200 &&
        this.rowDetail.receiptType < 300
      ) {
        type = 'outWarehouse'
      } else if (
        this.rowDetail.receiptType > 300 &&
        this.rowDetail.receiptType < 400
      ) {
        type = 'tallying'
      }
      return type
    },
    baseFormData() {
      const row = this.rowDetail || {}
      // console.log(this.options)
      return getEditBaseFormData(this, { ...row }, this.options)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getTaskTableItems(this, this.options)
    },
    tableItem() {
      return getDetailTableItems(this, this.options)
    },
    tableTaskItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  created() {
    this.getReceiptMonitorDetail()
  },
  methods: {
    // 获取容器类型
    async getContainerTypeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType')
      )
      if (code) return
      this.containerTypeList = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    async getReceiptMonitorDetail() {
      const params = {
        receiptNo: this.rowDetail.receiptNo,
        receiptType: this.rowDetail.receiptType
        // 'receiptNo': 'DJ000000001',
        // receiptNo: 'CKD20220253202202220000000018',
        // 'receiptType': 6
      }
      const { data, code } = await this.$httpService(
        this.$apiStore.monitorManage('getReceiptMonitorDetail'),
        params
      )
      if (code !== 0) return
      // const statusObj = myTransform.arrToObject(
      //   this.options.logStatusList,
      //   'value',
      //   'label'
      // )
      data.receiptMonitorLogVos &&
        data.receiptMonitorLogVos.forEach((element) => {
          if (this.abnormalCodeLis.indexOf(element.taskStatus) !== -1) {
            element.color = '#0bbd87'
          } else {
            element.color = '#f00'
          }
          element.content = this.getDictLabel(this.options.receiptStatusList, element.taskStatus)
        })
      if (data.receiptMonitorLogVos && data.receiptMonitorLogVos.length) {
        data.receiptMonitorLogVos.unshift({
          content: this.$t('lang.gles.orderManage.start'),
          color: '#0bbd87'
        })
        data.receiptMonitorLogVos.push({
          content: this.$t('lang.gles.orderManage.end'),
          color: '#f00'
        })
      }
      this.receiptMonitorTaskVos = data.receiptMonitorTaskVos || []
      this.receiptMonitorLogVos = data.receiptMonitorLogVos || []
      if (this.rowDetail.receiptType > 0 && this.rowDetail.receiptType < 100) {
        this.receiptMonitorDtVos = data.upMaterialMonitorDtVos || []
      } else {
        this.receiptMonitorDtVos = data.receiptMonitorDtVos || []
      }
      this.detailObj = data
      // console.log(this.receiptMonitorLogVos)
    },
    handleClick() {
      console.log(this.activeName)
      if (this.rowDetail.receiptType > 0 && this.rowDetail.receiptType < 100) {
        const list =
          this.activeName === '1'
            ? this.detailObj.upMaterialMonitorDtVos
            : this.detailObj.downMaterialMonitorDtVos
        this.receiptMonitorDtVos = list || []
      }
    },
    // 自动跳过
    handleAuto() {
      const params = {
        receiptNo: this.rowDetail.receiptNo,
        receiptType: this.rowDetail.receiptType,
        executeMode: this.rowDetail.executeMode
      }
      this.$confirm(this.$t('lang.gles.orderManage.isAutoSkip'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      })
        .then(() => {
          this.$httpService(
            this.$apiStore.monitorManage('postSkip'),
            params
          ).then(({ code }) => {
            if (code !== 0) {
              return
            }
            this.$message.success(this.$t('lang.gles.common.handleSuccess'))
            this.handleClose()
          })
        })
        .catch(() => {})
    },
    // 重试
    handleRetry() {
      const params = {
        receiptNo: this.rowDetail.receiptNo,
        receiptType: this.rowDetail.receiptType,
        executeMode: this.rowDetail.executeMode
      }
      this.$confirm(this.$t('lang.gles.orderManage.isSureRetry'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      })
        .then(() => {
          this.$httpService(
            this.$apiStore.monitorManage('postRetry'),
            params
          ).then(({ code }) => {
            if (code !== 0) {
              return
            }
            this.$message.success(this.$t('lang.gles.common.handleSuccess'))
            this.handleClose()
          })
        })
        .catch(() => {})
    },
    // 人工完成
    handleComplete() {
      const params = {
        receiptNo: this.rowDetail.receiptNo,
        receiptType: this.rowDetail.receiptType,
        executeMode: this.rowDetail.executeMode
      }
      this.$confirm(this.$t('lang.gles.orderManage.isManualCompletion'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      })
        .then(() => {
          this.$httpService(
            this.$apiStore.monitorManage('postFinish'),
            params
          ).then(({ code }) => {
            if (code !== 0) {
              return
            }
            this.$message.success(this.$t('lang.gles.common.handleSuccess'))
            this.handleClose()
          })
        })
        .catch(() => {})
    },
    // 取消
    handleCancel() {
      const params = {
        receiptNo: this.rowDetail.receiptNo,
        receiptType: this.rowDetail.receiptType,
        executeMode: this.rowDetail.executeMode
      }
      this.$confirm(this.$t('lang.gles.strategy.strategy.isSureCancel'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      })
        .then(() => {
          this.$httpService(
            this.$apiStore.monitorManage('postCancel'),
            params
          ).then(({ code }) => {
            if (code !== 0) {
              return
            }
            this.$message.success(this.$t('lang.gles.common.handleSuccess'))
            this.handleClose()
          })
        })
        .catch(() => {})
    },
    // 关闭
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table__header-wrapper {
  overflow: auto;
}
/deep/ .el-button.is-disabled {
  color: #fff !important;
  background-color: #c0c4cc;
  border-color: #c0c4cc;
}
/deep/ .el-drawer__header {
  margin-bottom: 5px;
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
  padding: 0 15px;
}
.drawer-wrapper {
  padding: 10px 0 0 0 !important;
}
// .drawer-head {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 20px 0px 10px;
// }
.drawer-info {
  display: flex;

  p {
    padding-left: 10px;
  }
}
.baseinfo,
.taskinfo {
  margin-bottom: 10px;
}
.baseinfo-title,
.taskinfo-title,
.loginfo-title {
  margin-bottom: 10px;
  font-weight: 800;

  &::before {
    content: '';
    display: inline-block;
    height: 21px;
    width: 4px;
    border-radius: 4px;
    background: #409eff;
    margin-right: 10px;
    vertical-align: text-bottom;
  }
}
.error-tip {
  display: inline-block;
  margin-left: 20px;
  color: #f00;
}
.loginfo-timeline {
  padding: 10px 30px 0;
}
</style>
