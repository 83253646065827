var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic-data-list"},[_c('el-tabs',{on:{"tab-click":_vm.handleTabChange},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.orderMonitorLabelList),function(tab){return _c('el-tab-pane',{key:tab.value,attrs:{"label":tab.label,"name":tab.value}})}),1),_c('query-view',{ref:"myQueryView",attrs:{"name":"receiptNo","value":_vm.formModel.receiptNo,"placeholder":_vm.$t('lang.gles.orderManage.pleaseReceiptNo'),"query-list":_vm.moreQueryData},on:{"update:value":function($event){return _vm.$set(_vm.formModel, "receiptNo", $event)},"baseSearch":_vm.handleBaseSearch,"moreSearch":_vm.handleMoreQuery}}),_c('el-card',{staticClass:"table-card"},[_c('div',{staticClass:"operator-wrapper"},[_c('column-config',{attrs:{"list":_vm.columnConfigList},on:{"update:list":function($event){_vm.columnConfigList=$event}}})],1),_c('m-table',{attrs:{"table-item":_vm.tableItem,"table-data":_vm.tableData,"page-data":_vm.pageData,"extend-config":_vm.tableConfig},on:{"autoItem":_vm.handleAutoItem,"completeItem":_vm.handleCompleteItem,"cancelItem":_vm.handleCancelItem,"pageChange":_vm.pageChange},scopedSlots:_vm._u([{key:"clickSlot",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"list-detail-item",on:{"click":function($event){return _vm.handleDetail(row, 'OrderItem')}}},[_vm._v(" "+_vm._s(row.receiptNo)+" ")])]}},{key:"stationSlot",fn:function(ref){
var row = ref.row;
return [(row.receiptType > 0 && row.receiptType < 100)?_c('span',{staticClass:"list-detail-item",on:{"click":function($event){return _vm.handleDetail(row, 'StationItem')}}},[_vm._v(" "+_vm._s(row.demandLocationCode)+" ")]):_c('span',[_vm._v(_vm._s(row.demandLocationCode))])]}},{key:"materialSlot",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"list-detail-item",on:{"click":function($event){return _vm.handleDetail(row, 'MaterialItem')}}},[_vm._v(" "+_vm._s(row.materialCode)+" ")])]}},{key:"robotSlot",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"list-detail-item",on:{"click":function($event){return _vm.handleDetail(row, 'RobotItem')}}},[_vm._v(" "+_vm._s(row.robotCode)+" ")])]}}])})],1),(_vm.detailDialogVisible)?_c('detail-dialog',{attrs:{"visible":_vm.detailDialogVisible,"page-type":_vm.pageType,"is-auto":_vm.isRowAuto,"is-complete":_vm.isRowComplete,"is-retry":_vm.isRowRetry,"is-disabled-complete":_vm.isRowDisabledComplete,"is-disabled-cancel":_vm.isRowDisabledCancel,"mode":_vm.mode,"row-detail":_vm.row,"options":_vm.detailOptions},on:{"update:visible":function($event){_vm.detailDialogVisible=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }