<template>
  <div class="basic-data-list">
    <el-tabs v-model="activeTab" @tab-click="handleTabChange">
      <el-tab-pane
        v-for="tab in orderMonitorLabelList"
        :key="tab.value"
        :label="tab.label"
        :name="tab.value"
      />
    </el-tabs>
    <query-view
      ref="myQueryView"
      name="receiptNo"
      :value.sync="formModel.receiptNo"
      :placeholder="$t('lang.gles.orderManage.pleaseReceiptNo')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreQuery"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableConfig"
        @autoItem="handleAutoItem"
        @completeItem="handleCompleteItem"
        @cancelItem="handleCancelItem"
        @pageChange="pageChange"
      >
        <template #clickSlot="{ row }">
          <span
            class="list-detail-item"
            @click="handleDetail(row, 'OrderItem')"
          >
            {{ row.receiptNo }}
          </span>
        </template>
        <!-- 需求工位详情 -->
        <template #stationSlot="{ row }">
          <span
            v-if="row.receiptType > 0 && row.receiptType < 100"
            class="list-detail-item"
            @click="handleDetail(row, 'StationItem')"
          >
            {{ row.demandLocationCode }}
          </span>
          <span v-else>{{ row.demandLocationCode }}</span>
        </template>
        <!-- 物料编码 -->
        <template #materialSlot="{ row }">
          <span
            class="list-detail-item"
            @click="handleDetail(row, 'MaterialItem')"
          >
            {{ row.materialCode }}
          </span>
        </template>
        <!-- 机器人详情 -->
        <template #robotSlot="{ row }">
          <span
            class="list-detail-item"
            @click="handleDetail(row, 'RobotItem')"
          >
            {{ row.robotCode }}
          </span>
        </template>
      </m-table>
    </el-card>
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :page-type="pageType"
      :is-auto="isRowAuto"
      :is-complete="isRowComplete"
      :is-retry="isRowRetry"
      :is-disabled-complete="isRowDisabledComplete"
      :is-disabled-cancel="isRowDisabledCancel"
      :mode="mode"
      :row-detail="row"
      :options="detailOptions"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import DetailDialog from './detail.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import {
  getMoreQueryFormData,
  getSearchTableItem,
  excepteAbnormalList
} from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import myTransform from '@/libs_sz/utils/transform'
import { mapState } from 'vuex'

export default {
  name: 'OrderMonitor',
  components: {
    ColumnConfig,
    QueryView,
    MTable,
    DetailDialog
  },
  mixins: [listMixins],
  data() {
    return {
      excepteAbnormalList,
      pageType: '',
      detailDialogVisible: false,
      tableData: [],
      formModel: {
        receiptNo: ''
      },
      tableConfig: {
        sortNum: false,
        checkBox: false,
        operateWidth: '168px',
        operate: [
          {
            event: 'autoItem',
            getLabel: this.getAutoLabel,
            getDisabled: this.isAutoDisabled,
            confirm: true,
            getConfirmMessage: this.getAutoConfimMsg
          },
          {
            event: 'completeItem',
            label: this.$t('lang.gles.orderMonitor.handleCompleteBtn'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.orderManage.isManualCompletion'),
            customClass: 'success',
            getDisabled: this.isDisabledComplete
          },
          {
            event: 'cancelItem',
            label: this.$t('lang.gles.workflow.taskStatus.cancel'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.strategy.strategy.isSureCancel'),
            customClass: 'danger',
            getDisabled: this.isDisabledCancel
          }
        ]
      },
      // 物料编码
      materialCodeList: [],
      // 物料名称
      materialNameList: [],
      // 所有容器编码
      containerArchivesAllList: [],
      // 周转容器编码
      turnoverContainerCodeList: [],
      // 容器类型
      containerTypeList: [],
      // 单据状态列表
      // receiptStatusList: [],
      // 日志专用单据状态
      logStatusList: [],
      activeTab: '',
      orderMonitorLabelList: [],
      // 单据类型
      receiptTypeList: [],
      // 更多查询单据状态
      moreReceiptStatusList: [],
      // 单据状态
      receiptStatusList: [],
      // 更多查询 任务状态
      moreTaskStatusList: [],
      // 列表任务状态
      taskStatusList: [],
      // 任务类型
      subTaskType: [],
      // 执行模式
      executeModeList: [],
      // 选中行是否 自动完成
      isRowAuto: false,
      // 选中行是否 人工完成
      isRowComplete: false,
      // 选中行是否 重试
      isRowRetry: false,
      // 选中行是否 禁用人工完成
      isRowDisabledComplete: false,
      // 选中行是否 禁用取消
      isRowDisabledCancel: false,
      queryApi: 'upDownMaterialMonitorList'
    }
  },
  computed: {
    ...mapState('base', ['operatingModeList', 'gradeStatusList', 'baseGoodsPositionTypeList', 'factoryPositionTypeList']),
    ...mapState('materialManage', ['basicUnitList', 'materialTowardList']),
    moreQueryData() {
      return getMoreQueryFormData(this)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    },
    detailOptions() {
      const options = {
        goodsPositionList: this.goodsPositionList,
        receiptTypeList: this.receiptTypeList,
        receiptStatusList: this.receiptStatusList,
        taskTypeList: this.taskTypeList,
        taskStatusList: this.taskStatusList,
        executeModeList: this.executeModeList,
        operatingModeList: this.operatingModeList,
        materialCodeList: this.materialCodeList,
        materialNameList: this.materialNameList,
        containerTypeList: this.containerTypeList,
        turnoverContainerCodeList: this.turnoverContainerCodeList,
        containerArchivesAllList: this.containerArchivesAllList,
        basicUnitList: this.basicUnitList,
        gradeStatusList: this.gradeStatusList,
        baseGoodsPositionTypeList: this.baseGoodsPositionTypeList,
        factoryPositionTypeList: this.factoryPositionTypeList,
        materialTowardList: this.materialTowardList,
        logStatusList: this.logStatusList
      }
      return options
    }
  },
  watch: {
    orderMonitorLabelList() {
      this.activeTab = this.orderMonitorLabelList[0]?.value ?? ''
    }
  },
  async created() {
    this.doSearch(this.formModel)
    this.initSelectList()
    this.initRobotDictList()
    this.initMoreQuerySelect()
  },
  methods: {
    /**
     * 初始化下拉选项
     */
    initSelectList() {
      this.getDictOptions('orderMonitorLabel', 'orderMonitorLabelList')
      this.getDictOptions('taskStatus', 'taskStatusList')
      this.getDictOptions('subTaskType', 'taskTypeList')
      if (!this.operatingModeList?.length) {
        this.$store.dispatch('base/getOperatingModeList')
      }
      if (!this.basicUnitList?.length) {
        this.$store.dispatch('materialManage/getBasicUnitList')
      }
      if (!this.gradeStatusList?.length) {
        this.$store.dispatch('base/getGradeStatusList')
      }
      if (!this.materialTowardList?.length) {
        this.$store.dispatch('materialManage/getMaterialToward')
      }
      if (!this.baseGoodsPositionTypeList?.length) {
        this.$store.dispatch('base/getBaseGoodsPositionTypeList')
      }
      if (!this.factoryPositionTypeList?.length) {
        this.$store.dispatch('base/getFactoryPositionTypeList')
      }
    },
    /**
     * 更多查询下拉选项
     */
    initMoreQuerySelect() {
      this.getBaseData(
        'getGoodsPositionListByType',
        'goodsPositionList',
        'goodsPositionCode'
      )
      this.getMaterialCodeList()
      this.getContainerTypeList()
      this.getTurnoverContainerCodeList()
      this.getContainerArchivesAllList()
    },
    handleTabChange() {
      const val = this.activeTab
      switch (val) {
        case 'robotUpAndDown':
          this.initRobotDictList()
          this.queryApi = 'upDownMaterialMonitorList'
          break
        case 'manualUpAndDown':
          this.initManualDictList()
          this.queryApi = 'artificialMonitorList'
          break
        case 'tally':
          this.initTallyDictList()
          this.queryApi = 'tallyMonitorList'
          break
        default:
          break
      }
      this.tableData = []
      this.doSearch(this.formModel, this.queryApi)
    },
    initRobotDictList() {
      this.getDictOptions('upDownMaterialReceiptType', 'receiptTypeList')
      this.getDictOptions('upDownMaterialsBillStatus', 'moreReceiptStatusList')
      this.getDictOptions('materialsBillStatus', 'receiptStatusList')
      this.getDictOptions('upDownTaskStatus', 'moreTaskStatusList')
      this.getDictOptions('upDownMaterialBillSubTaskType', 'moreTaskTypeList')
      this.getDictOptions('executeMode', 'executeModeList')
    },
    initManualDictList() {
      this.getDictOptions('artificialReceiptType', 'receiptTypeList')
      this.getDictOptions('artificialBillStatus', 'moreReceiptStatusList')
      this.getDictOptions('storeInStatus', 'receiptStatusList')
      this.getDictOptions('artificialSubTaskStatus', 'moreTaskStatusList')
      this.getDictOptions('artificialSubTaskType', 'moreTaskTypeList')
      this.getDictOptions('standardExecuteMode', 'executeModeList')
    },
    initTallyDictList() {
      this.getDictOptions('tallyReceiptType', 'receiptTypeList')
      this.getDictOptions('monitorTallyReceiptStatus', 'moreReceiptStatusList')
      this.getDictOptions('tallyReceiptStatus', 'receiptStatusList')
      this.getDictOptions('tallyTaskStatus', 'moreTaskStatusList')
      this.getDictOptions('tallyBillSubTaskType', 'moreTaskTypeList')
      this.getDictOptions('standardExecuteMode', 'executeModeList')
    },
    getAutoLabel(row = {}) {
      if (this.isAuto(row)) {
        return this.$t('lang.gles.orderMonitor.handleAutoBtn')
      } else if (this.isRetry(row)) {
        return this.$t('lang.gles.orderMonitor.handleRetryBtn')
      } else {
        return this.$t('')
      }
    },
    getAutoConfimMsg(row = {}) {
      if (this.isAuto(row)) {
        return this.$t('lang.gles.orderManage.isAutoSkip')
      } else if (this.isRetry(row)) {
        return this.$t('lang.gles.orderManage.isSureRetry')
      } else {
        return this.$t('确定操作？')
      }
    },
    isAutoDisabled(row) {
      return false
    },
    /**
     * 是否自动跳过
     * TODO：上下料单 任务状态 101、102、103 是否可以自动跳过
     * 上下料单[50, 60, 70, 80, 101, 102, 103]
     */
    isAuto(row = {}) {
      return (
        (row.receiptType === 301 &&
          ![0, 100].includes(row.receiptStatus) &&
          [50, 60, 70, 80].includes(row.taskStatus)) ||
        (row.receiptType > 0 &&
          row.receiptType < 100 &&
          ![99, 100].includes(row.receiptStatus) &&
          [50, 60, 70, 80].includes(row.taskStatus))
      )
    },
    /**
     * TODO 理货单 单据状态为 -6 时是否能重试
     * 是否 重试
     */
    isRetry(row = {}) {
      if (row.executeMode === 1) {
        return row.receiptType < 100 && ([201].includes(row.receiptStatus) || [3, 30, 202].includes(row.taskStatus))
      }
      return (
        (row.receiptType > 0 &&
          row.receiptType < 100 &&
          ![99, 100].includes(row.receiptStatus) &&
          ((row.taskId && [3, 23, 30, 24, 41].includes(row.taskStatus)) ||
            (!row.taskId && row.receiptStatus === 50))) ||
        (row.receiptType === 301 &&
          ![0, 99, 100].includes(row.receiptStatus) &&
          ((row.taskId &&
            [3, 23, 30, 24, 41, 42, 70].includes(row.taskStatus)) ||
            (!row.taskId && [11, -2, -6, -3, 21, -7].includes(row.receiptStatus))))

      // (!row.taskId && [11, -6, -3, 21].includes(row.receiptStatus))))
      )
    },
    /**
     * 是否  人工完成
     */
    isComplete(row = {}) {
      return (
        row.receiptType === 301 ||
        (row.receiptType > 0 && row.receiptType < 100)
      )
    },
    /**
     * 是否禁用 “人工完成”
     */
    isDisabledComplete(row = {}) {
      if (row.executeMode === 1) {
        return row.receiptType < 100 && (![201].includes(row.receiptStatus) || ![3, 30, 205, 202].includes(row.taskStatus))
      }
      return (
        (row.receiptType > 0 &&
          row.receiptType < 100 &&
          ([99, 100].includes(row.receiptStatus) ||
            (row.taskId &&
              ![3, 23, 30, 24, 41, 42, 90].includes(row.taskStatus)) ||
            (!row.taskId && row.receiptStatus !== 50))) ||
        (row.receiptType === 301 &&
          ([0, 100].includes(row.receiptStatus) ||
            (row.taskId &&
              ![3, 23, 30, 24, 41, 42, 70].includes(row.taskStatus)) ||
            (!row.taskId && ![11, -6, -3, 21, -7, -2].includes(row.receiptStatus)))) || [101, 201].includes(row.receiptType)
      )
    },
    /**
     * 是否禁用 “取消”
     * 1、机器人上下料单：单据状态【取消】、【完成】、【上料完成】不能取消，
                    任务类型为【放料任务】不能取消，任务类型为【上料任务】或者【下料任务】时，任务状态为【操作料完成】时不能取消
     * 2、人工出入库：
     * 3、理货：单据状态【已完成：100】，【已取消：0】不能取消，任务类型为【放料任务】且任务状态为【操作料完成：80】时不能取消
     */
    isDisabledCancel(row = {}) {
      if (row.executeMode === 1) {
        // 搬运异常处理
        return row.receiptType < 100 && ([99, 100].includes(row.receiptStatus) || [300].includes(row.taskStatus))
      }
      return (
        (row.receiptType < 100 &&
          ([99, 100, 22].includes(row.receiptStatus) || row.taskType === 20 || ([30, 40].includes(row.taskType) && row.taskStatus === 80))) ||
            (row.receiptType > 100 &&
              row.receiptType < 300 &&
              ([0, 50].includes(row.receiptStatus) ||
            row.taskType === 20 ||
            ([30, 40].includes(row.taskType) &&
              [82, 83].includes(row.taskStatus)))) ||
        (row.receiptType === 301 &&
          ([0, 100].includes(row.receiptStatus) ||
            (row.taskType === 20 && row.taskStatus === 80)))
      )
    },
    /**
     * 自动跳过
     * 重试
     */
    handleAutoItem({ row = {}}) {
      const api = this.isAuto(row) ? 'postSkip' : 'postRetry'
      const params = {
        receiptNo: row.receiptNo,
        receiptType: row.receiptType,
        executeMode: row.executeMode
      }
      this.$httpService(this.$apiStore.monitorManage(api), params).then(
        ({ code }) => {
          if (code !== 0) {
            return
          }
          this.$message.success(this.$t('lang.gles.common.handleSuccess'))
          this.doSearch(this.formModel)
        }
      )
    },
    /**
     * 人工完成
     */
    handleCompleteItem({ row = {}}) {
      const params = {
        receiptNo: row.receiptNo,
        receiptType: row.receiptType,
        executeMode: row.executeMode
      }
      this.$httpService(
        this.$apiStore.monitorManage('postFinish'),
        params
      ).then(({ code }) => {
        if (code !== 0) {
          return
        }
        this.$message.success(this.$t('lang.gles.common.handleSuccess'))
        this.doSearch(this.formModel)
      })
    },
    /**
     * 取消
     */
    handleCancelItem({ row = {}}) {
      console.log('handleCancelItem', row)
      const params = {
        receiptNo: row.receiptNo,
        receiptType: row.receiptType,
        executeMode: row.executeMode
      }
      this.$httpService(
        this.$apiStore.monitorManage('postCancel'),
        params
      ).then(({ code }) => {
        if (code !== 0) {
          return
        }
        this.$message.success(this.$t('lang.gles.common.handleSuccess'))
        this.doSearch(this.formModel)
      })
    },
    async getBaseData(url, array, label) {
      const { data } = await this.$httpService(this.$apiStore.base(url))
      const list = data.map((item) => ({
        label: label === 'factoryPositionCode' ? item[label] : item.code,
        value: item.id,
        ...item
      }))
      this[array] = list
    },
    // 获取容器类型
    async getContainerTypeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType')
      )
      if (code) return
      this.containerTypeList = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    // 所有容器编码
    async getContainerArchivesAllList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('getContainerArchivesAllList')
      )
      if (code) return
      this.containerArchivesAllList =
        data &&
        data.map((item) => {
          return { label: item.containerArchivesCode, value: item.id }
        })
    },
    // 获取周转容器编码
    async getTurnoverContainerCodeList() {
      const params = {
        containerTypePatterns: [
          'containerPatternCrate',
          'containerPatternBarrel'
        ]
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerCode'),
        params
      )
      if (code) return
      this.turnoverContainerCodeList = myTransform.arrToOptions(
        data,
        'containerArchivesCode',
        'id'
      )
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialOption')
      )
      if (code) return
      this.materialCodeList = data.map((item) => {
        return { label: item.materialCode, value: item.id }
      })
      this.materialNameList = data.map((item) => {
        return { label: item.materialName, value: item.id }
      })
    },
    /**
     * 查看详情
     */
    handleDetail(row, pageType) {
      // if (pageType === 'OrderItem') {
      //   this.logStatusList = this.statusList(row)
      // }
      this.pageType = pageType
      this.row = row
      this.isRowAuto = this.isAuto(row)
      this.isRowRetry = this.isRetry(row)
      this.isRowComplete = this.isComplete(row)
      this.isRowDisabledComplete = this.isDisabledComplete(row)
      this.isRowDisabledCancel = this.isDisabledCancel(row)
      this.mode = 'detail'
      this.detailDialogVisible = true
    },
    dataFun(data) {
      const obj = this._.cloneDeep(data)
      for (const key in obj) {
        if (obj[key] === '') {
          delete obj[key]
        }
      }
      return obj
    },
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreQuery(event) {
      this.formModel = { ...event }
      const params = this.dataFun(event)
      this.doSearch(params, this.queryApi)
      // this.$refs.myQueryView.reset()
    },
    /**
     * 接口查询
     */
    async doSearch(params) {
      const api = this.queryApi
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.monitorManage(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount: totalRecordCount }
        const TableItemCountProps = ['receiptNo', 'demandLocationCode', 'taskId', 'materialCode', 'robotCode']
        const list = this.$tool.pxCountWidth(recordList, TableItemCountProps, getSearchTableItem(this))
        this.tableData = list || []
      } catch (error) {
        console.log(error)
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 获取数据列表
     * @param {*} apiName 接口名
     * @param {*} params 请求参数
     * @returns 选项列表
     */
    getDictOptions(dictCode, array) {
      this.$httpService(
        this.$apiStore.base('queryDictByCode'),
        {
          dictCode
        }
      ).then(({ data, code }) => {
        if (code) return
        const recordList = data.baseDictDetails || []
        this[array] = recordList.map((item) => {
          return {
            label: this.$t(item.detailName),
            value: item.detailCodeType ? Number(item.detailCode) : item.detailCode
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-button.is-disabled {
  color: #c0c4cc;
}
</style>
